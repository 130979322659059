<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" width="70%" v-model="visible" :confirmLoading="btnsLoading" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-tabs type="card">
            <a-tab-pane key="1" tab="基本信息">
                <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:6}" :wrapper-col="{span:18}">
                  <div class="title">基本信息</div>
                  <a-row>
                    <a-col :md="12">
                        <a-form-model-item label="大区事务所" prop="" :autoLink="false" ref="test">
                          <a-cascader
                            :disabled="handle === 'edit' ? true : false"
                            v-model="dealerId"
                            @change="onDealerChange"
                            :options="dealerList"
                            :field-names="{ label: 'title', value: 'id', children: 'children' }" placeholder="请选择经销商" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                      <a-form-model-item label="经销商" prop="contractType">
                        <SelectDealerModal :value.sync="rowData.dealerName" :officeId="rowData.firmId"  @select="toSelectConsumer"></SelectDealerModal>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :md="12">
                        <a-form-model-item label="收货人" prop="customerName">
                            <a-input :disabled="handle === 'edit' ? true : false" v-model="rowData.customerName" placeholder="客户名"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="联系方式" prop="customerContact">
                            <a-input :disabled="handle === 'edit' ? true : false" v-model="rowData.customerContact" placeholder="客户联系方式"></a-input>
                        </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :md="12">
                      <a-form-model-item label="收货地址" prop="provinceId">
                        <PCD
                          ref="pcd"
                          placeholder="请选择区域"
                          :province.sync="rowData.provinceName"
                          :city.sync="rowData.cityName"
                          :district.sync="rowData.areaName"
                          :provinceId.sync="rowData.provinceId"
                          :cityId.sync="rowData.cityId"
                          :districtId.sync="rowData.areaId"
                          :disabled="handle === 'edit' ? true : false">
                        </PCD>
                      </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="详细收货地址" prop="address">
                            <a-input :disabled="handle === 'edit' ? true : false" v-model="rowData.address" placeholder="详细收货地址"></a-input>
                        </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :md="12">
                      <a-form-model-item label="供应商" prop="supplierId" :autoLink="false" ref="supplier">
                        <a-select :disabled="handle === 'edit' ? true : false" @change="onChange" placeholder="请选择供应商" v-model="rowData.supplierId">
                          <a-select-option :value="item.id" v-for="(item, index) in supplierList" :key="index">{{item.title}}</a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                      <a-form-model-item label="仓库" prop="warehouseId" v-if="changeRadio == 1">
                        <a-select :disabled="handle === 'edit' ? true : false" placeholder="请选择仓库" v-model="rowData.warehouseId">
                          <a-select-option :value="item.id" v-for="(item, index) in warehouseList" :key="index">{{item.title}}</a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    
                    <!-- <a-col :md="12">
                      <a-form-model-item label="订单类型" prop="orderType">
                        <a-select :disabled="handle === 'edit' ? true : false" placeholder="请选择订单类型" v-model="rowData.orderType">
                          <a-select-option :value="item.id" v-for="(item, index) in orderTypeList" :key="index">{{item.title}}</a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col> -->
                  </a-row>
                  <a-row>
                    <a-col :md="12">
                      <a-form-model-item label="收货单位" prop="consigneeType">
                        <a-radio-group :disabled="handle === 'edit' ? true : false" @change="handleChange" :options="consigneeOptions" v-model="rowData.consigneeType" />
                        <a-input v-if="checkRadio == 2" v-model="rowData.companyName" placeholder="请输入公司名称"></a-input>
                      </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                      <a-form-model-item label="合同类型" prop="contractType">
                        <a-select :disabled="handle === 'edit' ? true : false" placeholder="请选择合同类型" v-model="rowData.contractType">
                          <a-select-option :value="item.id" v-for="(item, index) in contractTypeList" :key="index">{{item.title}}</a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :md="12">
                      <a-form-model-item label="上传附件">
                        <QiniuUpload :disabled="handle === 'edit' ? true : false" type="image" :value.sync="rowData.filesVOS"></QiniuUpload>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :md="24">
                      <a-form-model-item label="备注说明" prop="" :label-col="{span:3}" :wrapper-col="{span:21}">
                        <a-textarea :disabled="handle === 'edit' ? true : false" v-model="rowData.orderRemark" placeholder=""></a-textarea>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <div class="title">商品信息</div>
                  <a-row>
                    <a-col :md="24">
                      <a-form-model-item label="添加商品" :label-col="{span:3}" :wrapper-col="{span:21}">
                        <a-button type="primary" @click="onOpenProModal()">添加商品</a-button>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <div class="table_outer" v-if="productList.length > 0">
                    <a-table :data-source="productList" bordered :rowKey="record => record.id" :pagination="false">
                      <a-table-column title="商品名称" data-index="name" :width="200" :ellipsis="true" align="left"></a-table-column>
                      <a-table-column title="商品分类" data-index="categoryName" :width="120" :ellipsis="true" align="center"></a-table-column>
                      <a-table-column title="单位" data-index="" :width="100" :ellipsis="true" align="center"></a-table-column>
                      <a-table-column title="数量" data-index="purchaseNum" :width="140" :ellipsis="true" align="center">
                        <template slot-scope="text, row">
                          <a-input-number @change="getRabateList" :min="1" v-model="row.purchaseNum"></a-input-number>
                        </template>
                      </a-table-column>
                      <a-table-column title="建议零售价" data-index="price" :width="120" :ellipsis="true" align="right">
                        <template slot-scope="text">{{text.toFixed(2)}}</template>
                      </a-table-column>
                      <a-table-column title="标准开票价（D价）" data-index="sellPrice" :width="120" :ellipsis="true" align="right">
                        <template slot-scope="text">{{text.toFixed(2)}}</template>
                      </a-table-column>
                      <a-table-column title="实际单价" data-index="" :width="120" :ellipsis="true" align="right">
                        <template slot-scope="text, row">{{ row.realPirce.toFixed(2) }}</template>
                      </a-table-column>
                      <a-table-column title="可使用折扣金额" data-index="canUsedRebatePrice" :width="120" :ellipsis="true" align="right">
                        <template slot-scope="text">{{text.toFixed(2)}}</template>
                      </a-table-column>
                      <a-table-column title="已使用折扣金额" data-index="usedRebateTotal" :width="120" :ellipsis="true" align="right">
                        <template slot-scope="text">{{text.toFixed(2)}}</template>
                      </a-table-column>
                      <a-table-column title="合计" data-index="" :width="120" :ellipsis="true" align="center">
                        <template slot-scope="text, row">{{ ((row.realPirce * row.purchaseNum) - row.usedRebateTotal).toFixed(2) }}</template>
                      </a-table-column>
                      <a-table-column title="政策" data-index="" :width="300" :ellipsis="true" align="center">
                        <template slot-scope="text, row, index">
                          <a-select placeholder="请选择" v-if="row.policyInfoVOS != null" style="width: 100%" v-model="row.optKey" @change="onPolicySelectChange(index)">
                            <a-select-option :value="item.optKey" v-for="(item, index) in row.policyInfoVOS" :key="index">{{item.name}}{{item.discountNum}}</a-select-option>
                          </a-select>
                          <span v-else>--</span>
                        </template>
                      </a-table-column>
                      <a-table-column title="备注" data-index="remark" :width="120" :ellipsis="true" align="center">
                        <template slot-scope="text,row">
                          <a-input v-model="row.remark" placeholder="请输入"></a-input>
                        </template>
                      </a-table-column>
                      <a-table-column title="操作" data-index="action" :width="120" :ellipsis="true"  align="center">
                        <template slot-scope="text, row">
                          <a @click="onDelete(row.goodsId)">删除</a>
                        </template>
                      </a-table-column>
                    </a-table>
                  </div>
                  <div class="table_outer" v-if="productList.length > 0">
                    <div class="rebate">
                      <div class="rebate-title">选择折扣：</div>
                      <div style="display: flex; margin-top: 30px; align-items: flex-end;">
                        <div class="money-outer">
                          <div class="rebate-total-money">可用折扣金额：3600000 </div>
                          <div class="rebate-order-money">当前订单最高可用折扣金额： {{ orderUsableRebateTotal.toFixed(2) }}</div>
                        </div>
                        <div class="btns" @click="onChoose()"><u>选择其他折扣</u></div>
                      </div>
                    </div>
                  </div>
                  <div class="product_Amount_info" v-if="productList.length > 0">
                    <div class="content">
                      <div class="text"></div>
                      <div class="text"><span>商品总数：</span><span>{{ totalQuantity }}</span><span style="margin-left: 20px"> 订单总金额：</span><span>{{ totalMoney.toFixed(2) }}</span></div>
                      <div class="text"><span>折扣：</span><span class="text-color">- {{ totalRebatePrice.toFixed(2) }}</span></div>
                      <div class="text"><span>政策：</span><span class="text-color">- {{ policyTotalPrice.toFixed(2) }}</span></div>
                      <div class="text"><span>实付金额：￥</span><span class="text-color real-total-price">{{ realTotalPrice.toFixed(2) }}</span></div>
                    </div>
                  </div>
                </a-form-model>
            </a-tab-pane>
            <a-tab-pane key="2" tab="操作日志" v-if="handle !='add'">
              <log-page></log-page>
            </a-tab-pane>
        </a-tabs>
      <ProductModal ref="ProductModal" @get-product-list="getProductList"></ProductModal>
      <ChooseRebate ref="ChooseRebate" @checkout="getRebateIds"></ChooseRebate>
    </a-modal>
</template>

<script>
import { editOrderInfo, selectByIdOrderInfo, addOrderInfo } from '../api/OrderShipInfoApi'
import ProductModal from './ProductModal.vue'
import ChooseRebate from './ChooseRebate.vue'
import LogPage from './LogPage.vue'
import { formatMoneyOfRound } from '../../../utils/util.js'
// import SelectDealerModal from '../../common/SelectDealerModal.vue'



export default {
    components: {
      ProductModal,
      ChooseRebate,
      LogPage,
    },
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                bigAreaName: [
                  { required: true, message: '请选择大区', trigger: 'change' }
                ],
                firmName: [
                  { required: true, message: '请选择事务所', trigger: 'change' }
                ],
                dealerId: [
                  { required: true, message: '请选择收货地址', trigger: 'change' }
                ],
                provinceId: [
                  { required: true, message: '请选择收货地址', trigger: 'change' }
                ],
                customerName: [
                  { required: true, message: '请输入客户名', trigger: 'blur' }
                ],
                customerContact: [
                  { required: true, message: '请输入客户联系方式', trigger: 'blur' }
                ],
                consigneeType: [
                  { required: true, message: '请选择抬头类型', trigger: 'change' }
                ],
                payType: [
                  { required: true, message: '请支付选择方式', trigger: 'change' }
                ],
                contractType: [
                  { required: true, message: '请选择合同类型', trigger: 'change' }
                ],
                orderType: [
                  { required: true, message: '请选择订单类型', trigger: 'change' }
                ],
                supplierId: [
                  { required: true, message: '请选择供应商', trigger: 'change' }
                ],
                address: [
                  { required: true, message: '请输入详细收货地址', trigger: 'blur' }
                ],
                taxType: [
                  {required: true, message: '请选择发票类型', trigger: 'change'}
                ],
                taxTitle: [
                  {required: true, message: '请输入发票抬头', trigger: 'blur'}
                ],
                taxCompany: [
                  {required: true, message: '请输入单位名称', trigger: 'blur'}
                ],
                taxNum: [
                  {required: true, message: '请输入纳税人识别号', trigger: 'blur'}
                ],
            },
            regionList: [],
            payTypeList: [],
            contractTypeList: [],
            orderTypeList: [],
            goodsChannelList: [],
            consigneeOptions: [
                {
                    label: '个人',
                    value: 1
                },
                {
                    label: '公司',
                    value: 2
                },
            ],
            productList: [], // 选中商品
            dealerList: [],
            dealerId: [],
            checkedList: [],
            totalQuantity: 0,  // 总数量
            totalMoney: 0,  // 总金额
            realTotalPrice: 0,  // 实付金额
            totalRebatePrice: 0,  // 折扣总金额
            policyTotalPrice: 0,  // 政策总金额,
            orderUsableRebateTotal: 0, // 当前订单可使用折扣总额
            btnsLoading: false,
            checkedRebateList: [],
            checkRadio: 1,
            supplierList: [],
            warehouseList: [],
            changeRadio: 0
        }
    },
    computed: {

    },
    methods: {
      // 获取行数据
      setRowData(row, handle){
          this.handle = handle
          this.visible = true
          this.productList = []
          this.dealerId = []
          this.rowData = {}
          this.getEnumerationList()
          if(handle === 'add') {
              this.$set(this.rowData, 'consigneeType', 1)
              this.$set(this.rowData, 'taxType', 1)
          }else if(handle === 'edit') {
            selectByIdOrderInfo(row.id).then(res => {
              this.dealerId = [res.body.bigAreaId, res.body.firmId, res.body.dealerId]
              this.rowData = res.body
              this.rowData.contractType = Number(res.body.contractType)
              this.rowData.orderType = Number(res.body.orderType)
              this.rowData.consigneeType = Number(res.body.consigneeType)
              this.$nextTick(() => {
                this.$refs.pcd.setPCD()
              })
            })
          }
      },

      // 获取页面枚举数据
      getEnumerationList() {
          this.axios.get('/api/firm/mhiac/mhiacArea/listAll').then(res => {  // 获取大区数据
            this.regionList = res.body
          })
          this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then(res => {  // 获取大区事务所经销商数据
            this.dealerList = res.body
          })
          this.axios.get('/api/base/system/dictionary/selectByCode/'+ '合同类型').then(res => {  // 获取字典合同类型
            this.contractTypeList = res.body
          })
          this.axios.get('/api/base/system/dictionary/selectByCode/'+ '订单类型').then(res => {  // 获取字典订单类型
            this.orderTypeList = res.body
          })
          this.axios.get('/api/base/system/dictionary/selectByCode/'+ '供应商').then(res => {  // 获取字典供应商
            this.supplierList = res.body
          })
          this.axios.get('/api/base/system/dictionary/selectByCode/'+ '仓库').then(res => {  // 获取字典仓库
            this.warehouseList = res.body
          })
      },

      // 收获类型为单位时打开弹框
      handleChange(e) {
        this.checkRadio = e.target.value
      },

      onChange(val) {
        this.$refs.supplier.onFieldChange()
        console.log(val)
        if(val == 11) {
          this.changeRadio = 1
        }else {
          this.changeRadio = 2
        }
      },

      onDealerChange(val, data) {
        this.$refs.test.onFieldChange()
        this.rowData.bigAreaId = val[0]
        this.rowData.bigAreaName = data[0].title
        this.rowData.firmId = val[1]
        this.rowData.firmName = data[1].title
        // this.rowData.dealerId = val[2]
        // this.rowData.dealerName = data[2].title
      },

      toSelectConsumer(row) {
        this.$set(this.rowData, 'dealerId', row.id)
        this.$set(this.rowData, 'dealerName', row.name)
      },

      // 监听当前选中数据
      onPolicySelectChange(index) {
        let tmp = this.productList[index]
        let tmpArr = tmp.policyInfoVOS.filter(x => x.optKey === tmp.optKey)
        let discountNum = tmpArr.length > 0 ? tmpArr[0].discountNum : ''
        tmp.realPirce = tmp.sellPrice * discountNum
        this.$set(this.productList, index ,tmp)
        this.getRabateList()
      },

      // 根据商品数量、政策的不同查询当前商品可用折扣和已使用折扣
      getRabateList() {
        let reqData = {
          dealerId: this.rowData.dealerId,
          orderInfoDetailDTOList: this.productList,
        }

        this.axios.post('/api/order/order/orderInfo/getNewRebatePriceByChangPro', reqData).then(res => {
          this.getProductData(res.body.orderInfoDetailGoodsVOS)
        })

        this.getTotalGoodsInfo()
      },

      // 根据商品数量、政策的不同查询当前商品可用折扣和已使用折扣
      getProductData(resList) {
        this.productList.forEach(e => {
          let isExist = false
          let canUsedRebatePrice = 0
          let usedRebateTotal = 0
          resList.forEach(i => {
            if(e.id === i.id) {
              isExist = true
              canUsedRebatePrice = i.canUsedRebatePrice < 0 ? 0 : i.canUsedRebatePrice
              usedRebateTotal = i.usedRebateTotal < 0 ? 0 : i.usedRebateTotal
            }
            if(isExist) {
              e.canUsedRebatePrice = canUsedRebatePrice
              e.usedRebateTotal = usedRebateTotal
            }else {
              e.canUsedRebatePrice = 0
              e.usedRebateTotal = 0
            }
          })
        })
        this.getTotalGoodsInfo()
      },

      // 获取选中折扣
      getRebateIds(item, data) {
        this.checkedRebateList = item

        this.getProductData(data)

      },

      // 打开选择折扣弹框
      onChoose() {
        this.$refs.ChooseRebate.isShow(this.rowData.dealerId, this.checkedRebateList, this.productList)
      },

      // 打开商品弹框
      onOpenProModal() {
        if(!this.rowData.dealerId) {
          return this.$notification.warning({message: '请选择经销商'})
        }

        this.$refs.ProductModal.isShow(this.rowData.dealerId, this.productList)
      },

      getProductList(data) {
        const arr = new Map()
        let vo = data.orderInfoDetailGoodsVOS.map(e => {
          return {
            ...e,
            goodsId: e.id,
            optKey: e.policyInfoVOS != null ? e.policyInfoVOS[0].optKey : 0,
            purchaseNum: 1,
            realPirce: e.sellPrice,
            remark: ''
          }
        })
        const list = this.productList.concat(vo)

        // 最大折扣组合ID
        this.checkedRebateList = data.usedRebateIdList
        this.productList = list.filter(e => !arr.has(e.goodsId) && arr.set(e.goodsId, 1))
        console.log(this.productList)
        this.getTotalGoodsInfo()
        // 计算当前商品可用总折扣金额
        this.orderUsableRebateTotal = this.productList.map(e => e.canUsedRebatePrice).reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next))
      },

      // 获取商品价格汇总
      getTotalGoodsInfo() {
        this.totalQuantity = this.productList.map(e => e.purchaseNum).reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next))  // 总数量
        this.totalMoney = this.productList.map(e => e.sellPrice * e.purchaseNum).reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next))  // 总金额
        this.totalRebatePrice = this.productList.map(e => e.usedRebateTotal).reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next))  // 折扣优惠总金额
        const tmpPrice = this.productList.map(e => e.purchaseNum * e.realPirce).reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next))  // 政策后金额
        this.policyTotalPrice = formatMoneyOfRound(this.totalMoney) - formatMoneyOfRound(tmpPrice)  // 政策优惠总金额
        this.realTotalPrice = formatMoneyOfRound(this.totalMoney) - formatMoneyOfRound(this.totalRebatePrice) - formatMoneyOfRound(this.policyTotalPrice)  // 实付金额
        // 计算当前商品可用总折扣金额
        this.orderUsableRebateTotal = this.productList.map(e => e.canUsedRebatePrice).reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next))
      },

      // 删除选中的商品
      onDelete(id) {
        this.productList.splice(this.productList.findIndex(e => { return e.goodsId === id }), 1)
        this.getRabateList()
      },
      // 表单提交
      toSubmit() {
        this.$refs.form.validate(async valid => {
            if (!valid) {
              return
            }
            this.rowData.orderInfoTax = {}
            this.rowData.orderInfoTax.consigneeType = this.rowData.consigneeType 
            this.rowData.usedRebateIds = this.checkedRebateList
            this.rowData.payAmount = this.realTotalPrice
            this.rowData.orderInfoDetailDTOList = this.productList

            if(this.rowData.supplierId == 11) {
              if(!this.rowData.warehouseId) {
                return this.$message.warning('请选择选仓库')
              }
            }
            
            const res = this.handle === 'add' ? await addOrderInfo(this.rowData) : await editOrderInfo(this.rowData)
            if (res.code === 200) {
                this.$notification.success({ message: res.message })
                this.$bus.$emit('getList')
                this.visible = false
                this.rowData = {}
            } else {
                this.$notification.error({ message: res.message })
                this.btnsLoading = false
            }
        })
      }
    },
    created() {

    },

}
</script>

<style lang="scss" scoped>
  .table_outer {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
  }
  .product_Amount_info {
    text-align: right;
    .content {
      .text {
        margin-left: 20px;
        line-height: 30px;
      }
      .text-color {
        color: red;
      }
      .real-total-price {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .rebate {
    background: rgb(242, 242, 242);
    padding: 20px;
    width: 100%;
    height: 159px;
    .money-outer {
      color: rgb(88, 212, 88);
      width: 80%;
      padding-left: 50px;
    }
    .btns {
      cursor: pointer;
      color: rgb(22, 155, 213);
      font-weight: 600;
    }
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 40px;
  }
</style>
