<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-28 10:07:00
 * @LastEditors: hutian
 * @LastEditTime: 2021-05-28 10:53:21
-->
<template>
<div class=''>
  <a-table :data-source="tableData" :loading="tableLoading" :rowKey="record => record.id" :pagination="page" bordered @change="changeTable">
    <a-table-column title="修改者" data-index="modifyUser" align="center"></a-table-column>
    <a-table-column title="修改日期" data-index="modifyDate" align="center"></a-table-column>
    <a-table-column title="操作内容" data-index="content" align="center"></a-table-column>
  </a-table>
</div>
</template>

<script>

export default {
  name:'',
  data() {
    return {
      tableLoading: false,
      btnsLoading: false,
      tableData: [],
      handle: '',
      searchData: {},
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0
      },
      selectedRowKeys: [],
      selectedRows: [],
    }
  },

  computed: {

  },
  created() {

  },
  methods: {
    getList() {
      this.axios.get(`/api/order/system/orderInfoRecord/list`).then(res => {
        this.tableData = res.body
      })
    },
    
    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getList(1)
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  }
}
</script>

<style lang='scss' scoped>
</style>