/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-24 09:25:47
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-17 18:57:48
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listOrderShipInfo = params => axios({
    url: '/api/order/order/orderInfo/salesOrder',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addOrderShipInfo = params => axios({
    url: '/api/order_ship_info/system/orderShipInfo/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editOrderShipInfo = params => axios({
    url: '/api/order_ship_info/system/orderShipInfo/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delOrderShipInfo = params => axios({
    url:'/api/order_ship_info/system/orderShipInfo/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const delOrderInfo = params => axios({
  url:'/api/order/order/orderInfo/del/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const rollbackStatus = params => axios({
  url:'/api/order/order/orderInfo/rollbackStatus/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const selectByIdOrderShipInfo = params => axios({
    url: '/api/order/order/orderInfo/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
